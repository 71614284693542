import { Badge } from '@synoptic/ui-kit/badge/badge.js';
import { TrophyIcon } from '@synoptic/ui-kit/icons/react/trophy.tsx';
import { clsx } from 'clsx';
import { Link } from '@/routes-utils/navigation.tsx';
import { paths } from '@/routes-utils/paths.ts';
import { Wrap } from '@synoptic/ui-kit/wrap.js';
import { badge, iconContainer, labelContent } from './post-label.css.ts';

export const TournamentLabel = ({
  label,
  tournamentId,
  className,
  linkToTournament = false,
}: {
  label: string;
  tournamentId: string;
  className?: string;
  linkToTournament?: boolean;
}) => (
  <Wrap
    if={linkToTournament}
    with={Link}
    to={paths.makeTournament(tournamentId)}
  >
    <Badge
      color={'purple'}
      className={clsx(badge, className)}
      interactive={linkToTournament}
    >
      <div className={iconContainer}>
        <TrophyIcon width={16} height={16} />
      </div>
      <span className={labelContent}>{label}</span>
    </Badge>
  </Wrap>
);
